import AgencyFileUpload from 'components/common/AgencyFileUpload'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';
import { deleteMediaApi, markMainImageApi, saveMediaDataApi, validateFile } from 'services/propertyService';
import { Checkbox, Image, Popconfirm, Upload } from 'antd';
import LoaderMu from 'components/common/LoaderMu';
import { getAgencyProfileData } from 'services/agencyService';
import { InboxOutlined } from '@ant-design/icons';
import DragNdrop from 'components/common/DragNdDrop';


const { Dragger } = Upload;

function MediaForm({ property_id, propertyMedia, getPropertyData }) {
	const [loading, setLoading] = useState(false)
	const { t } = useTranslation()

	const [mediaList, setMediaList] = useState([])
	const [mediaUploadError, setMediaUploadError] = useState("")
	const [isValid, setIsValid] = useState(false)
	const [addWatermark, setWatermarkToggle] = useState(false)
	const [agencyData, setAgencyData] = useState({})

	// PREVIOUSLY UPLOADED FILE
	const [previousMedia, setPreviousMedia] = useState(propertyMedia ?? [])

	// HANDLE FILE SUBMISSION API CALLING
	const handleSaveMedia = () => {
		setLoading(true)

		if (mediaList?.length > 0) {
			if (isValid) {
				const formData = new FormData()
				formData.append("id", property_id)
				formData.append("addWatermark" , addWatermark ?? false)
				for (let i = 0; i < mediaList.length; i++) {
					formData.append('browseImages', mediaList[i]);
				}
				saveMediaDataApi(formData).then((response) => {
					toast.success(response?.message ?? "Media saved.")
					setMediaList([])
					getPropertyData()
					setLoading(false)
				}).catch((error) => {
					setLoading(false)
					toast.error(error?.response?.data?.error_description
						? error?.response?.data?.error_description
						: error?.response?.data?.message ?? error?.message)
				})

				// CALL API HERE TO UPLOAD IMAGES
			} else {
				setLoading(false)
				toast.error(mediaUploadError && mediaUploadError !== '' ? mediaUploadError : 'Images are not valid!')
			}
		} else {
			toast.error(t("AT LEAST ONE MEDIA IS REQUIRED"))
			setLoading(false)
		}


	}


	// HANDLE ON CHANGE OF FILE UPLOAD
	const handleMediaUpload = (event) => {
		// CURRENT UPLOADING FILES
		const uploadedFiles = event?.target.files
		setMediaUploadError('')
		// COUNT OF PREVIOUS UPLOADED AND CURRENT UPLOADED FILES MUST NOT BE GREATER THAN 10
		const fileCount = uploadedFiles?.length + previousMedia?.length
		if (fileCount > 10) {
			toast.error(t("MAXIMUM_IMAGES_TEXT"))
			setLoading(false)
		}
		else {
			// LOOP THROUGH ALL FILES AND CHECK VALIDATION 
			const newFiles = Array.from(uploadedFiles);
			setMediaList(newFiles)
			const newValidationResults = newFiles.map((file) => {
				return validateFile(file);
			});
			Promise.all(newValidationResults).then((result) => {
				setIsValid(true)
			}).catch((error) => {
				setMediaUploadError(error)
				setLoading(false)
			});
		}
	}
	// HANDLE MEDIA DELETE
	const handleMediaDelete = (id) => {
		setLoading(true)
		deleteMediaApi(id).then((response) => {
			getPropertyData()
			toast.success(response?.message)
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(error?.response?.data?.error_description
				? error?.response?.data?.error_description
				: error?.response?.data?.message ?? error?.message)
		})
	}

	// HANDLE MAIN IMAGE MARK
	const handleMainImageMark = (value, id) => {
		if (value === true) {
			setLoading(true)
			markMainImageApi(id).then((response) => {
				getPropertyData()
				toast.success(response?.message)
				setLoading(false)
			}).catch((error) => {
				setLoading(false)
				toast.error(error?.response?.data?.error_description
					? error?.response?.data?.error_description
					: error?.response?.data?.message ?? error?.message)
			})
		}

	}

	useEffect(() => {
		// IF NO IMAGE MARKED AS MAIN THEN MARK A DEFAULT IMAGE
		const mainImage = propertyMedia?.filter((element) => element?.mainImage === true)
		if (propertyMedia?.length > 0 && mainImage?.length < 1) {
			handleMainImageMark(true, propertyMedia[0].id)
		}
		setPreviousMedia(propertyMedia)
	}, [propertyMedia])

	// THIS IS IMPLEMENTED FOR DRAG AND DROP 

	// HANDLE ON CHANGE OF FILE UPLOAD
	// const handleMediaAnUpload = (event) => {
	// 	// CURRENT UPLOADING FILES
	// 	// const uploadedFiles = event?.target.files
	// 	const uploadedFiles = event?.fileList

	// 	setMediaUploadError('')
	// 	// COUNT OF PREVIOUS UPLOADED AND CURRENT UPLOADED FILES MUST NOT BE GREATER THAN 10
	// 	const fileCount = uploadedFiles?.length + previousMedia?.length
	// 	if (fileCount > 10) {
	// 		toast.error('You can only upload 10 files!')
	// 		setLoading(false)
	// 	}
	// 	else {
	// 		// LOOP THROUGH ALL FILES AND CHECK VALIDATION 
	// 		const newFiles = Array.from(uploadedFiles);

	// 		const newValidationResults = newFiles.map((file) => {

	// 			return validateFile(file?.originFileObj);
	// 		});
	// 		Promise.all(newValidationResults).then((result) => {
	// 			setIsValid(true)

	// 			setMediaList(newFiles.map((image) => image?.originFileObj))
	// 		}).catch((error) => {
	// 			setMediaUploadError(error)
	// 			setLoading(false)
	// 		});
	// 	}
	// }

	const props = {

		multiple: true,
		beforeUpload: () => false,
		fileList: mediaList,
		accept: 'image/*',
		showUploadList :true,
		onChange: (info) => {
			console.log(info.fileList);
			// handleMediaAnUpload(info)
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files);
		},
	};

	

	// FETCH AGENCY PROFILE DATA
	const fetchAgencyData = () => {
	  getAgencyProfileData().then((response) => {
		setAgencyData(response)
		setWatermarkToggle(response?.watermark ?? false)
	  }).catch((error) => {
		toast.error(
		  error?.response?.data?.error_description
			? error?.response?.data?.error_description
			: error?.response?.data?.message ?? error?.message
		);
	  })
	}
	
	// FETCH AGENCY PROFILE DATA
	useEffect(() => {
	  fetchAgencyData()
	}, [])
  

	return (
		<>
			<h4 className="title fz17 mb30">{t("PROPERTY MEDIA")}</h4>
			{
				agencyData?.watermark ? 
				<div className="ant-check-style mb30">
				<Checkbox
					id="addWatermark"
					name="addWatermark"
					onChange={(event) => setWatermarkToggle(event?.target?.checked)}
					checked={addWatermark}
				>{t("ADD WATERMARK")}</Checkbox>
			</div> : ""
			}
			
			<LoaderMu loading={loading} />
			<div className="col-lg-12">
				<div className="upload-img position-relative overflow-hidden bdrs12 text-center mb30 px-2">
					<div className="icon mb30">
						<span className="flaticon-upload" />
					</div>
					<h4 className="title fz17 mb10">{t("UPLOAD PHOTOS OF YOUR PROPERTY")}</h4>
					<p className="text  mb-0">{t("PHOTOS MUST BE JPEG OR PNG FORMAT")}</p>
					<p className="text  mb-0"><span className='fw-bold'>{t("MINIMUM DIMENSIONS")}: </span> {t("MINIMUM_DIMENSION_TEXT")}</p>
					<p className="text mb25"><span className='fw-bold'>{t("MAXIMUM NUMBER OF IMAGES")}:</span> {t("MAXIMUM_IMAGES_TEXT")}</p>
					<div className="row justify-content-center">
						<div className="col-md-12 col-xl-5">
							{/* IMPLEMENTED FOR DRAG AND DROP */}
							{/* <Dragger type='button' {...props} className='propertyMedia'>
								<div className="icon fs-60">
									<span className="flaticon-upload" />
								</div>
								<p className='fw-bold'>{`${mediaList && mediaList?.length > 0 ? mediaList?.length + " files selected" : 'No image choosen'}`}</p>
								<p className="ant-upload-text">Click or drag file to this area to upload</p>
							</Dragger> */}
							<AgencyFileUpload
								id="myFile"
								multiple={true}
								fileName={mediaList && mediaList?.length > 0 ? { name: `${mediaList?.length} files selected` } : { name: t("NO IMAGE CHOOSEN") }}
								labelText={t("UPLOAD IMAGES")}
								fileLabelClass="btn-white ud-btn"
								name="myFile"
								handleChange={(event) => { handleMediaUpload(event) }}
								className={`ud-btn btn-white w-md-100`}
							/>
							<span className='text-danger'>{mediaUploadError}</span>
							<p className="mb25 mt25">
								<button type="button" className={`ud-btn btn-dark`}
									onClick={handleSaveMedia}>
									{t("SUBMIT")}
								</button>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="col-lg-12">
				<div className="row profile-box position-relative d-md-flex align-items-end mb50">
					{
						previousMedia && previousMedia?.length > 0 ?
							previousMedia?.map((media, index) => {
								return <div key={index + 1} className='col-sm-6 col-md-4 col-lg-3  col-xl-2 mb20'>
									{
										media?.mainImage ? <span className="text-thm fw-bold">{t("COVER IMAGE")}</span> : <Checkbox className="checkbox-style1" onChange={(event) => { handleMainImageMark(event.target.checked, media?.id) }}><span className="text-dark fw-bold">{t("MARK COVER")}</span></Checkbox>
									}

									{/* IF ONE IMAGE LEFT THEN DO NOT SHOW DELETE BUTTON  */}
									<div className="profile-img position-relative overflow-hidden bdrs12 mb20-sm mr20 ">
										<Image className="w-100" src={media?.thumbnailImageName} alt=" Logo" preview={{ src: media?.imageName }} />
										{
											previousMedia?.length > 1 ? <Popconfirm title={t("DELETE THE IMAGE")}
												description={t("ARE YOU SURE TO DELETE THE IMAGE")}
												onConfirm={() => {
													handleMediaDelete(media?.id)
												}}
												// onCancel={cancel}
												okText={t("YES")}
												cancelText={t("NO")}>
												<button type="button" className="tag-del border-none" >
													<span className="fas fa-trash-can" />
												</button>
											</Popconfirm> : ""
										}

									</div></div>
							}) : ''
					}
				</div>
			</div>
		</>
	)
}

export default MediaForm
